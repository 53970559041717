import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import educationalCaucusBylaws from "../../assets/files/educational-caucus-bylaws.pdf";
import blackCaucusBylaws from "../../assets/files/black-caucus-bylaws.pdf";

function StandingCommittee() {
  const standingCommittee = [
    {
      name: "Budget",
      members: [
        {
          name: "Jan Lee Weinberg",
          email: "janleewater@gmail.com",
        },
      ],
      description:
        "Develop and monitor the budget for Clackamas County Democrats.",
    },
    {
      name: "Campaign",
      members: [
        {
          name: "Vacant",
          email: "",
        },
      ],
      description:
        "Participate in canvasing, phone banking, texting, letter writing. and developing other effective Get Out the Vote strategies.",
    },
    {
      name: "Communications",
      members: [
        {
          name: "Lisa Nichols",
          email: "pdxnichols@gmail.com",
        },
      ],
      description:
        "Write articles and post updated content and visuals on our website and our social media properties. Write and design additional outreach materials as needed.",
    },
    {
      name: "Events and Facilities/Election Projection",
      members: [
        {
          name: "Diane Sawyer",
          email: "mamamoonbeam@gmail.com",
        },
      ],
      description:
        "Help plan and staff gatherings and meetings to promote Clackamas County Democrats and our policies and ideals.",
    },
    {
      name: "Justice",
      members: [
        {
          name: "Christine Kennedy",
          email: "christinekennedy215@gmail.com",
        },
      ],
      description:
        "Develop strategies and outreach related to environmental, economic, racial, and social equity.",
    },
    {
      name: "Labor",
      members: [
        {
          name: "Vacant",
          email: "",
        },
      ],
      description:
        "Monitor and share information about policies that affect workers, unions, and businesses in Clackamas County; draft resolutions and strategies to take action when needed.",
    },
    {
      name: "Neighborhood Leader",
      members: [
        {
          name: "Susan Plack",
          email: "plaxxxx@gmail.com",
        },
      ],
      description:
        "Join the team of dedicated volunteers like you; learn strategies and reach out to your Democratic neighbors (we provide contact info) to encourage them to vote in local, state, and national elections.",
    },
    {
      name: "Platform & Resolutions",
      members: [
        {
          name: "James Farley",
          email: "farley_james@rocketmail.com",
        },
      ],
      description: "",
    },
    // Rules
    {
      name: "Rules",
      members: [
        {
          name: "Diane Grover",
          email: "dgroverlaw@yahoo.com",
        },
      ],
      description: "",
    },
    // Technical Operations
    {
      name: "Technical Operations",
      members: [
        {
          name: "Dan Fischler",
          email: "techopschair@clackamasdems.org",
        },
        {
          name: "Jason Rambo",
          email: "jrambo@gmail.com",
        },
        {
          name: "Mike Weber",
          email: "skf_mikeweber@hotmail.com",
        },
      ],
      description:
        "Handle video conferencing, Google Workspace, database creation and support; provide technical support to users.",
    },
    // Volunteer
    {
      name: "Volunteer/PCP Recruitment",
      members: [
        {
          name: "Amber Tatge",
          email: "volunteer-team@clackamasdems.org",
        },
        {
          name: "Heidi Prokop",
          email: "volunteer-team@clackamasdems.org",
        },
      ],
      description: "",
    },
  ];

  const caucuses = [
    {
      name: "Black Caucus",
      description: "",
      members: [
        {
          name: "Mary-Elizabeth Harper",
          email: "mharper01@gmail.com",
        },
      ],
    },
    {
      name: "Education Caucus",
      description: "",
      members: [
        {
          name: "Al Rabchuk",
          email: "arabchuk@comcast.net",
        },
      ],
    },
    {
      name: "LatinX Caucus",
      description: "",
      members: [
        {
          name: "Vacant",
          email: "",
        },
      ],
    },
    {
      name: "LGBTQ Caucus",
      description: "",
      members: [
        {
          name: "Nick Walden Poublon",
          email: "nwpinpdx@gmail.com",
        },
      ],
    },
  ];

  const inFormation = [
    {
      name: "Veterans Subcommittee",
      description: "",
      members: [
        {
          name: "James Freed",
          email: "jfreedpdx@gmail.com",
        },
      ],
    },
    {
      name: "Young Democrats Subcommittee",
      description: "",
      members: [
        {
          name: "Lorreina Guyett",
          email: "slorreina369@gmail.com",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <MetaTags>
        <title>DPCC | Standing Committee and Caucuses</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs
        breadcrumb={{ pagename: "Standing Committee and Caucuses" }}
      />

      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Caucus Bylaws</h3>
                </div>
                <div className="card-body p-3">
                  <p style={{ marginBottom: "14px" }}>
                    <a href={educationalCaucusBylaws} download>
                      Download Educational Caucus Bylaws
                    </a>
                  </p>
                  <a href={blackCaucusBylaws} download>
                    Download Black Caucus Bylaws
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p style={{ marginBottom: "14px" }}>
                    Volunteer activities support the heart and core of our
                    county party! If you’d like to get involved, please get in
                    touch!
                  </p>
                  <a
                    href="https://www.clackamasdems.org/#/contacts-and-organization"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.clackamasdems.org/#/contacts-and-organization
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row px-md-5 p-sm-0">
            <h2>Committees</h2>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              {standingCommittee.map((committee) => {
                return (
                  <div className="card mb-5" key={committee.name}>
                    <div className="card-header">
                      <h3 style={{ marginBottom: "8px" }}>{committee.name}</h3>
                      <p style={{ fontSize: "16px" }}>
                        {committee.description}
                      </p>
                    </div>
                    <div className="card-body">
                      <ul>
                        {!committee.members.length && <li> Open</li>}
                        {committee.members.map((leader) => {
                          return (
                            <li key={leader.email}>
                              <p>
                                {leader.name} -{" "}
                                <a href={`mailto:${leader.email}`}>
                                  {leader.email}
                                </a>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row px-md-5 p-sm-0">
            <h2>Caucuses</h2>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              {caucuses.map((c) => {
                return (
                  <div className="card mb-5" key={c.name}>
                    <div className="card-header">
                      <h3 style={{ marginBottom: "8px" }}>{c.name}</h3>
                      <p style={{ fontSize: "16px" }}>{c.description}</p>
                    </div>
                    <div className="card-body">
                      <ul>
                        {!c.members.length && <li> Open</li>}
                        {c.members.map((leader) => {
                          return (
                            <li key={leader.email}>
                              <p>
                                {leader.name} -{" "}
                                <a href={`mailto:${leader.email}`}>
                                  {leader.email}
                                </a>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row px-md-5 p-sm-0">
            <h2>In Formation</h2>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              {inFormation.map((c) => {
                return (
                  <div className="card mb-5" key={c.name}>
                    <div className="card-header">
                      <h3 style={{ marginBottom: "8px" }}>{c.name}</h3>
                      <p style={{ fontSize: "16px" }}>{c.description}</p>
                    </div>
                    <div className="card-body">
                      <ul>
                        {!c.members.length && <li> Open</li>}
                        {c.members.map((leader) => {
                          return (
                            <li key={leader.email}>
                              <p>
                                {leader.name} -{" "}
                                <a href={`mailto:${leader.email}`}>
                                  {leader.email}
                                </a>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}
export default StandingCommittee;
